import { APIPurchaseResponse, PurchaseEntity } from "./../../api/studentApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import studentApi, {
  APIAddExisingCardInput,
  APIGetStudentFavoritesResponse,
  APIGetStudentResponse,
  APILoadDetailsTransactionResponse,
  APILoadTransactionsInput,
  APILoadTransactionsResponse,
  APIGetTransactionsInput,
  APIGetTransactionsResponse,
  StudentEntity,
  APIChangePINCardInput,
  APIReferFriendInput,
  APIReissueCardInput,
  APIReissueCardResponse,
  APIGetSettingSMSResponse,
  APIGetQRCodeResponse,
  APIPostSettingSMSResponse,
  APIPostSettingSMSInput,
  APIListMessagesResponse,
  MessageEntity,
  LoadTransactionEntity,
} from "../../api/studentApi";
import { TransactionEntity } from "../../api/transactionApi";
import { APIResponse } from "../../api/types";
import { onCompleteCB } from "../../utils/apiClient";

export const getStudent = createAsyncThunk<
  APIGetStudentResponse,
  onCompleteCB<APIGetStudentResponse> | undefined
>("student/get", (onComplete) => {
  return studentApi
    .getStudentData()
    .then(({ data }) => {
      onComplete?.(null, data);

      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const postStudent = createAsyncThunk<
  APIResponse<{}>,
  {
    data: StudentEntity;
    onComplete: onCompleteCB<APIResponse<{}>> | undefined;
  }
>("student/post", ({ data, onComplete }) => {
  return studentApi
    .postStudentData(data)
    .then(({ data }) => {
      onComplete?.(null, data);

      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const getStudentFavorites = createAsyncThunk<
  APIGetStudentFavoritesResponse,
  onCompleteCB<APIGetStudentFavoritesResponse> | undefined
>("student/get-favorites", (onComplete) => {
  return studentApi
    .getStudentFavorites()
    .then(({ data }) => {
      onComplete?.(null, data);

      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const postStudentFavorite = createAsyncThunk<
  APIResponse<{}>,
  {
    data: { m_id: string };
    onComplete: onCompleteCB<APIResponse<{}>> | undefined;
  }
>("student/post-favorite", ({ data, onComplete }) => {
  return studentApi
    .postStudentFavorite(data)
    .then(({ data }) => {
      onComplete?.(null, data);

      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const deleteStudentFavorite = createAsyncThunk<
  APIResponse<{}>,
  {
    data: { m_id: string };
    onComplete: onCompleteCB<APIResponse<{}>> | undefined;
  }
>("student/delete-favorite", ({ data, onComplete }) => {
  return studentApi
    .deleteStudentFavorite(data)
    .then(({ data }) => {
      onComplete?.(null, data);

      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const loadTransactions = createAsyncThunk<
  APILoadTransactionsResponse,
  {
    data: APILoadTransactionsInput;
    onComplete?: onCompleteCB<APILoadTransactionsResponse>;
  }
>("student/load-transactions", ({ data, onComplete }) => {
  return studentApi
    .loadTransactions(data)
    .then(({ data }) => {
      onComplete?.(null, data);

      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const loadDetailsTransaction = createAsyncThunk<
  APILoadDetailsTransactionResponse,
  {
    data: { order_number: string };
    onComplete?: onCompleteCB<APILoadDetailsTransactionResponse>;
  }
>("student/load-details-transaction", ({ data, onComplete }) => {
  return studentApi
    .loadDetailsTransaction(data)
    .then(({ data }) => {
      onComplete?.(null, data);

      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const getTransactions = createAsyncThunk<
  APIGetTransactionsResponse,
  {
    data: APIGetTransactionsInput;
    onComplete?: onCompleteCB<APIGetTransactionsResponse>;
  }
>("student/get-transactions", ({ data, onComplete }) => {
  return studentApi
    .getTransactions(data)
    .then(({ data }) => {
      onComplete?.(null, data);

      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const changePINCard = createAsyncThunk<
  APIResponse<{}>,
  {
    data: APIChangePINCardInput;
    onComplete: onCompleteCB<APIResponse<{}>> | undefined;
  }
>("student/change-pin-card", ({ data, onComplete }) => {
  return studentApi
    .changePINCard(data)
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const addExistingCard = createAsyncThunk<
  APIResponse<{}>,
  {
    data: APIAddExisingCardInput;
    onComplete: onCompleteCB<APIResponse<{}>> | undefined;
  }
>("student/add-existing-card", ({ data, onComplete }) => {
  return studentApi
    .addExistingCard(data)
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const referFriend = createAsyncThunk<
  APIResponse<{}>,
  {
    data: APIReferFriendInput;
    onComplete: onCompleteCB<APIResponse<{}>> | undefined;
  }
>("student/refer-friend", ({ data, onComplete }) => {
  return studentApi
    .referFriend(data)
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const reissueCard = createAsyncThunk<
  APIReissueCardResponse,
  {
    data: APIReissueCardInput;
    onComplete: onCompleteCB<APIReissueCardResponse> | undefined;
  }
>("student/reissue-card", ({ data, onComplete }) => {
  return studentApi
    .reissueCard(data)
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});
export const getSettingSMS = createAsyncThunk<
  APIGetSettingSMSResponse,
  onCompleteCB<APIGetSettingSMSResponse> | undefined
>("student/get-setting-sms", (onComplete = () => {}) => {
  return studentApi
    .getSettingSMS()
    .then(({ data }) => {
      onComplete(null, data);
      return data;
    })
    .catch((error) => {
      onComplete(error);
      throw new Error(error.response.data);
    });
});

export const getQRCode = createAsyncThunk<
  APIGetQRCodeResponse,
  onCompleteCB<APIGetQRCodeResponse> | undefined
>("student/get-qrcode", (onComplete = () => {}) => {
  return studentApi
    .getQRCode()
    .then(({ data }) => {
      onComplete(null, data);

      return data;
    })
    .catch((error) => {
      onComplete(error);
      throw new Error(error.response.data);
    });
});

export const postSettingSMS = createAsyncThunk<
  APIPostSettingSMSResponse,
  {
    data: APIPostSettingSMSInput;
    onComplete: onCompleteCB<APIPostSettingSMSResponse> | undefined;
  }
>("student/post-setting-sms", ({ data, onComplete }) => {
  return studentApi
    .postSettingSMS(data)
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const listMessages = createAsyncThunk<
  APIListMessagesResponse,
  onCompleteCB<APIListMessagesResponse> | undefined
>("student/list-messages", (onComplete) => {
  return studentApi
    .listMessages()
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export const purchaseMeals = createAsyncThunk<
  APIPurchaseResponse,
  {
    data: PurchaseEntity;
    onComplete: onCompleteCB<APIPurchaseResponse> | undefined;
  }
>("student/purchase", ({ data, onComplete }) => {
  return studentApi
    .purchaseMeal(data)
    .then(({ data }) => {
      onComplete?.(null, data);
      return data;
    })
    .catch((error) => {
      onComplete?.(error);
      throw new Error(error.response.data);
    });
});

export interface StudentState {
  hasCard: boolean;
  currentStudent: {
    isFetching: boolean;
    data?: APIGetStudentResponse["payload"];
  };
  postStudent: {
    isFetching: boolean;
  };
  getStudentFavorites: {
    isFetching: boolean;
    data: {
      favorites: Array<{ m_id: string; name: string }>;
    };
  };
  postStudentFavorite: {
    isFetching: boolean;
  };
  deleteStudentFavorite: {
    isFetching: boolean;
  };
  loadTransactions: {
    isFetching: boolean;
    data: {
      loadTransactions: Array<LoadTransactionEntity>;
    };
  };
  loadDetailsTransaction: {
    isFetching: boolean;
    data?: APILoadDetailsTransactionResponse["payload"];
  };
  getTransactions: {
    isFetching: boolean;
    data: {
      transactions: Array<TransactionEntity>;
    };
  };
  changePINCard: {
    isFetching: boolean;
  };
  addExistingCard: {
    isFetching: boolean;
  };
  referFriend: {
    isFetching: boolean;
  };
  reissueCard: {
    isFetching: boolean;
    data?: APIReissueCardResponse["payload"];
  };
  getSettingSMS: {
    isFetching: boolean;
    data?: APIGetSettingSMSResponse["payload"];
  };
  getQRCode: {
    isFetching: boolean;
    data?: APIGetQRCodeResponse["payload"];
  };
  postSettingSMS: {
    isFetching: boolean;
    data?: APIPostSettingSMSResponse["payload"];
  };
  listMessages: {
    isFetching: boolean;
    data: {
      transactions: Array<MessageEntity>;
    };
  };
  purchaseMeals: {
    isFetching: boolean;
    data?: APIResponse<any>["payload"];
  };
}

const initialStudentState: StudentState = {
  hasCard: false,
  currentStudent: {
    isFetching: false,
  },
  postStudent: {
    isFetching: false,
  },
  getStudentFavorites: {
    isFetching: false,
    data: {
      favorites: [],
    },
  },
  postStudentFavorite: {
    isFetching: false,
  },
  deleteStudentFavorite: {
    isFetching: false,
  },
  loadTransactions: {
    isFetching: false,
    data: {
      loadTransactions: [],
    },
  },
  loadDetailsTransaction: {
    isFetching: false,
  },
  getTransactions: {
    isFetching: false,
    data: {
      transactions: [],
    },
  },
  changePINCard: {
    isFetching: false,
  },
  addExistingCard: {
    isFetching: false,
  },
  referFriend: {
    isFetching: false,
  },
  reissueCard: {
    isFetching: false,
  },
  getSettingSMS: {
    isFetching: false,
  },
  getQRCode: {
    isFetching: false,
  },
  postSettingSMS: {
    isFetching: false,
  },
  listMessages: {
    isFetching: false,
    data: {
      transactions: [],
    },
  },
  purchaseMeals: {
    isFetching: false,
  },
};

const studentSlice = createSlice({
  name: "student",
  initialState: initialStudentState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStudent.pending, (state) => {
        state.currentStudent.isFetching = true;
      })
      .addCase(getStudent.fulfilled, (state, action) => {
        state.currentStudent.isFetching = false;
        state.currentStudent.data = action.payload.payload;
      })
      .addCase(getStudent.rejected, (state) => {
        state.currentStudent.isFetching = false;
      })
      .addCase(postStudent.pending, (state) => {
        state.postStudent.isFetching = true;
      })
      .addCase(postStudent.fulfilled, (state, action) => {
        state.postStudent.isFetching = false;
      })
      .addCase(postStudent.rejected, (state) => {
        state.postStudent.isFetching = false;
      })
      .addCase(getStudentFavorites.pending, (state) => {
        state.getStudentFavorites.isFetching = true;
      })
      .addCase(getStudentFavorites.fulfilled, (state, action) => {
        state.getStudentFavorites.isFetching = false;
        state.getStudentFavorites.data = action.payload.payload.data;
      })
      .addCase(getStudentFavorites.rejected, (state) => {
        state.getStudentFavorites.isFetching = false;
      })
      .addCase(postStudentFavorite.pending, (state) => {
        state.postStudentFavorite.isFetching = true;
      })
      .addCase(postStudentFavorite.fulfilled, (state, action) => {
        state.postStudentFavorite.isFetching = false;
      })
      .addCase(postStudentFavorite.rejected, (state) => {
        state.postStudentFavorite.isFetching = false;
      })
      .addCase(deleteStudentFavorite.pending, (state) => {
        state.deleteStudentFavorite.isFetching = true;
      })
      .addCase(deleteStudentFavorite.fulfilled, (state, action) => {
        state.deleteStudentFavorite.isFetching = false;
      })
      .addCase(deleteStudentFavorite.rejected, (state) => {
        state.deleteStudentFavorite.isFetching = false;
      })
      .addCase(loadTransactions.pending, (state) => {
        state.loadTransactions.isFetching = true;
      })
      .addCase(loadTransactions.fulfilled, (state, action) => {
        state.loadTransactions.isFetching = false;
        state.loadTransactions.data = action.payload.payload.data;
      })
      .addCase(loadTransactions.rejected, (state) => {
        state.loadTransactions.isFetching = false;
      })
      .addCase(loadDetailsTransaction.pending, (state) => {
        state.loadDetailsTransaction.isFetching = true;
      })
      .addCase(loadDetailsTransaction.fulfilled, (state, action) => {
        state.loadDetailsTransaction.isFetching = false;
        state.loadDetailsTransaction.data = action.payload.payload;
      })
      .addCase(loadDetailsTransaction.rejected, (state) => {
        state.loadDetailsTransaction.isFetching = false;
      })
      .addCase(getTransactions.pending, (state) => {
        state.getTransactions.isFetching = true;
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.getTransactions.isFetching = false;
        state.getTransactions.data = action.payload.payload.data;
      })
      .addCase(getTransactions.rejected, (state) => {
        state.getTransactions.isFetching = false;
      })
      .addCase(changePINCard.pending, (state) => {
        state.changePINCard.isFetching = true;
      })
      .addCase(changePINCard.fulfilled, (state, action) => {
        state.changePINCard.isFetching = false;
      })
      .addCase(changePINCard.rejected, (state) => {
        state.changePINCard.isFetching = false;
      })
      .addCase(addExistingCard.pending, (state) => {
        state.addExistingCard.isFetching = true;
      })
      .addCase(addExistingCard.fulfilled, (state, action) => {
        state.addExistingCard.isFetching = false;
      })
      .addCase(addExistingCard.rejected, (state) => {
        state.addExistingCard.isFetching = false;
      })
      .addCase(referFriend.pending, (state) => {
        state.referFriend.isFetching = true;
      })
      .addCase(referFriend.fulfilled, (state, action) => {
        state.referFriend.isFetching = false;
      })
      .addCase(referFriend.rejected, (state) => {
        state.referFriend.isFetching = false;
      })
      .addCase(reissueCard.pending, (state) => {
        state.reissueCard.isFetching = true;
      })
      .addCase(reissueCard.fulfilled, (state, action) => {
        state.reissueCard.isFetching = false;
        state.reissueCard.data = action.payload.payload;
      })
      .addCase(reissueCard.rejected, (state) => {
        state.reissueCard.isFetching = false;
      })
      .addCase(getSettingSMS.pending, (state) => {
        state.getSettingSMS.isFetching = true;
      })
      .addCase(getSettingSMS.fulfilled, (state, action) => {
        state.getSettingSMS.isFetching = false;
        state.getSettingSMS.data = action.payload.payload;
      })
      .addCase(getSettingSMS.rejected, (state) => {
        state.getSettingSMS.isFetching = false;
      })
      .addCase(getQRCode.pending, (state) => {
        state.getQRCode.isFetching = true;
      })
      .addCase(getQRCode.fulfilled, (state, action) => {
        state.getQRCode.isFetching = false;
        state.getQRCode.data = action.payload.payload;
      })
      .addCase(getQRCode.rejected, (state) => {
        state.getQRCode.isFetching = false;
      })
      .addCase(postSettingSMS.pending, (state) => {
        state.postSettingSMS.isFetching = true;
      })
      .addCase(postSettingSMS.fulfilled, (state, action) => {
        state.postSettingSMS.isFetching = false;
        state.postSettingSMS.data = action.payload.payload;
      })
      .addCase(postSettingSMS.rejected, (state) => {
        state.postSettingSMS.isFetching = false;
      })
      .addCase(listMessages.pending, (state) => {
        state.listMessages.isFetching = true;
      })
      .addCase(listMessages.fulfilled, (state, action) => {
        state.listMessages.isFetching = false;
        state.listMessages.data = action.payload.payload.data;
      })
      .addCase(listMessages.rejected, (state) => {
        state.listMessages.isFetching = false;
      })
      .addCase(purchaseMeals.pending, (state) => {
        state.purchaseMeals.isFetching = true;
      })
      .addCase(purchaseMeals.fulfilled, (state, action) => {
        state.purchaseMeals.isFetching = false;
        state.purchaseMeals.data = action.payload;
      })
      .addCase(purchaseMeals.rejected, (state) => {
        state.purchaseMeals.isFetching = false;
      });
  },
});

export const studentReducer = studentSlice.reducer;

export const studentActions = {
  ...studentSlice.actions,
  getStudent,
  postStudent,
  getStudentFavorites,
  postStudentFavorite,
  deleteStudentFavorite,
  loadTransactions,
  loadDetailsTransaction,
  getTransactions,
  changePINCard,
  addExistingCard,
  referFriend,
  reissueCard,
  getSettingSMS,
  getQRCode,
  postSettingSMS,
  listMessages,
  purchaseMeals,
};
